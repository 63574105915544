<div id="meetDiamond" class="landing-meetdiamond">
  <div class="section-title">
    <h1>FORESTFALCON Privacy Policy</h1>
    <span>How we handle your data.</span>
  </div>

<div id="features" class="landing-features">
  <div class="feature-row">
    <div class="feature-text-full">
      <br><h2>Privacy policy</h2>
      <p>This Privacy Policy explains how we treat Personal Information that you share while using the
        ForestFalcon platform and
        its communication channels (internal messaging, email, telephone, social media, etc.). Please read
        our
        privacy
        policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle
        your
        Personally
        Identifiable Information in accordance with our website. If you do not agree to any part of this
        Privacy
        Policy,
        then we cannot provide the services to you, and you should stop using the ForestFalcon platform.</p>




      <br><h2>What personal information do we collect from the people that visit our website or app?</h2> <p>When ordering or registering on our site, as appropriate, you may be asked to enter your name, email
        address,
        mailing address or other details to help you with your experience.</p> <br><h2>When do we collect information?</h2> <p>We collect information from you when you register on our site or mobile app, subscribe to a
        newsletter,
        respond to a survey or enter information on our site.</p> <br><h2>How do we use your information?</h2> <p>We may use the information we collect from you when you register, request a quote, sign up for our
        newsletter,
        respond to a survey or marketing communication, surf the website, or use certain other site features
        in
        the
        following ways:</p> <ul><li>To personalize user's experience and to allow us to deliver the type of content and product
        offerings in which you are most interested.</li> <li>To improve our website in order to better serve you.</li> <li>To allow us to better service you in responding to your customer service requests.</li> <li>To administer a contest, promotion, survey or other site feature.</li> <li>To send periodic emails regarding your order or other products and services.</li></ul> <br><h2>How do we protect visitor information?</h2> <p>Our website is scanned on a regular basis for security holes and known vulnerabilities in order to
        make
        your visit
        to our site as safe as possible.
        We use regular Malware Scanning.
        Your personal information is contained behind secured networks and is only accessible by a limited
        number of persons
        who have special access rights to such systems, and are required to keep the information
        confidential.
        In addition,
        all sensitive/credit information you supply is encrypted via Secure Socket Layer (SSL) technology.
        We implement a variety of security measures when a user enters, submits, or accesses their
        information
        to maintain
        the safety of your personal information.</p> <br><h2>Do we use 'cookies'?</h2> <p>Yes. Cookies are small files that a site or its service login provider transfers to your computer's
        hard
        drive
        through your Web browser (if you allow) that enables the site's or service loginProvider's systems
        to
        recognize your
        browser and capture and remember certain information. For instance, we use cookies to help us
        remember
        and process
        the items in your shopping cart. They are also used to help us understand your preferences based on
        previous or
        current site activity, which enables us to provide you with improved services. We also use cookies
        to
        help us
        compile aggregate data about site traffic and site interaction so that we can offer better site
        experiences and
        tools in the future.</p> <br><h2>Third Party Disclosure</h2> <p>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable
        information
        unless we
        provide you with advance notice. This does not include website hosting partners and other parties
        who
        assist us in
        operating our website, conducting our business, or servicing you, so long as those parties agree to
        keep
        this
        information confidential. We may also release your information when we believe release is
        appropriate to
        comply with
        the law, enforce our site policies, or protect ours or others' rights, property, or safety.
        However, non-personally identifiable visitor information may be provided to other parties for
        marketing,
        advertising, or other uses.</p> <br><h2>Third party links</h2> <p>Occasionally, at our discretion, we may include or offer third party products or services on our
        website. These
        third party sites have separate and independent privacy policies. We therefore have no
        responsibility or
        liability
        for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity
        of
        our site and
        welcome any feedback about these sites.
        We have implemented the following:
        • Demographics and Interests Reporting
        We along with third-party vendors, such as Google use first-party cookies (such as the Google
        Analytics
        cookies) and
        third-party cookies (such as the DoubleClick cookie) or other third-party identifiers together to
        compile data
        regarding user interactions with ad impressions, and other ad service functions as they relate to
        our
        website.</p> <br><h2>California Online Privacy Protection Act</h2> <p>CalOPPA is the first state law in the nation to require commercial websites and online services to
        post
        a privacy
        policy. The law's reach stretches well beyond California to require a person or company in the
        United
        States (and
        conceivably the world) that operates websites collecting personally identifiable information from
        California
        consumers to post a conspicuous privacy policy on its website stating exactly the information being
        collected and
        those individuals with whom it is being shared, and to comply with this policy. - See more at:
        CalOPPA.
        According to CalOPPA we agree to the following:
        Users can visit our site anonymously.
        Once this privacy policy is created, we will add a link to it on our home page, or as a minimum on
        the
        first
        significant page after entering our website.
        Our Privacy Policy link includes the word 'Privacy', and can be easily be found on the page
        specified
        above.
        <br>
        Users will be notified of any privacy policy changes on our Privacy Policy Page.
        <br>
        Users are able to change their personal information:</p> <ul><li>By emailing us</li> <li>By logging in to their account</li></ul> <br><h2>How does our site handle do not track signals?</h2> <p>We honor do not track signals and do not track, plant cookies, or use advertising when a Do Not
        Track
        (DNT) browser
        mechanism is in place.</p> <br><h2>Does our site allow third party behavioral tracking?</h2> <p>It's also important to note that we allow third party behavioral tracking</p> <br><h2>COPPA (Children Online Privacy Protection Act)</h2> <p>When it comes to the collection of personal information from children under 13, the Children's
        Online
        Privacy
        Protection Act (COPPA) puts parents in control. The Federal Trade Commission, the nation's consumer
        protection
        agency, enforces the COPPA Rule, which spells out what operators of websites and online services
        must do
        to protect
        children's privacy and safety online.
        We do not specifically market to children under 13.</p> <br><h2>Fair Information Practices</h2> <p>The Fair Information Practices Principles form the backbone of privacy law in the United States and
        the
        concepts
        they include have played a significant role in the development of data protection laws around the
        world.
        Understanding the Fair Information Practice Principles and how they should be implemented is
        critical to
        comply with
        the various privacy laws that protect personal information.
        In order to be in line with Fair Information Practices we will take the following responsive action,
        should a data
        breach occur:</p> <ul><li>We will notify the users via email within 1 business day</li> <li>We will notify the users via in site notification within 1 business day</li></ul> <p>We also agree to the individual redress principle, which requires that individuals have a right to
        pursue legally
        enforceable rights against data collectors and processors who fail to adhere to the law. This
        principle
        requires not
        only that individuals have enforceable rights against data users, but also that individuals have
        recourse to courts
        or a government agency to investigate and/or prosecute non-compliance by data processors.</p> <br><h2>CAN SPAM Act</h2> <p>The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for
        commercial
        messages, gives recipients the right to have emails stopped from being sent to them, and spells out
        tough penalties
        for violations.
        To be in accordance with CANSPAM we agree to the following:
        If at any time you would like to unsubscribe from receiving future emails, you can email us at
        info@meld.si and
        we will promptly remove you from ALL correspondence.</p> <br><h2>More information</h2> <p>If there are any questions regarding this privacy policy you may contact us using the email
        below.<br>
        Email: info@meld.si<br><br>
        Last Edited on 1st August 2020</p>
    </div>
  </div>
</div>
</div>
