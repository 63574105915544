<div id="contact" class="landing-contact">
  <div class="section-title">
    <h1>Get In Touch</h1>
    <span>Just drop us an email</span>
  </div>
  <div class="contact-wrapper">
    <div class="contact-left">
      <div class="card-contact">
        <h3>Say hi to the team</h3>
        <input placeholder="Full Name / Company" disabled />
        <input placeholder="Email Address" disabled />
        <textarea rows="8" placeholder="Your Message" autoResize="false" disabled></textarea>
        <button class="landing-btn" disabled><span>SEND MESSAGE</span></button>
      </div>
    </div>
    <div class="contact-right">
      <div class="contact-text">
        <h1>Feel free to contact us and we will get <br />
          back to you <br />
          as soon as possible.</h1>
        <div class="contact-info">
          <div class="info">
            <h2>Opening Hours</h2>
            <p>Monday - Friday <br />
              9am - 15pm <br />
              Weekend or holidays<br />
              Closed</p>
          </div>
          <div class="info">
            <h2>Address</h2>
            <p>MELD d.o.o.<br />
              Legen 38<br />
              SI-2383 Šmartno pri Slovenj Gradcu<br>
              info@meld.si</p>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="landing-footer">
    <a href="#" class="logo">
      <img src="../../assets/nx/fflogo.svg" alt="logo"/>
    </a>
    <div class="social">
      <a routerLink="/privacy"><i class="pi pi-list"></i> Privacy policy</a>
    </div>
  </div>

  <div class="landing-mask"></div>
</div>
