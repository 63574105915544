import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {FirstComponent} from './first/first.component';
import {PrivacyComponent} from './privacy/privacy.component';

const routes: Routes = [
  {
    path: 'privacy',
    component: PrivacyComponent,
  },
  {
    path: '**',
    component: FirstComponent,
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
