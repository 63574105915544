<div id="home" class="landing-header">
  <div class="landing-topbar">
    <a href="#home" class="logo">
      <img src="../../assets/nx/fflogo.svg" alt="logo"/>
    </a>

    <ul class="landing-menu">
      <li>
        <a onclick="closeMenu()" id="landing-menu-close" tabindex="0">
          <i class="pi pi-times"></i>
        </a>
      </li>
      <li class="landing-menu-item">
        <a href="#home">HOME</a>
      </li>
      <li class="landing-menu-item">
        <a href="#meetDiamond">ABOUT</a>
      </li>
      <li class="landing-menu-item">
        <a href="#features">FEATURES</a>
      </li>
      <li class="landing-menu-item">
        <a href="#pricing">PRICING</a>
      </li>
      <li class="contact landing-menu-item">
        <a href="#contact">CONTACT</a>
      </li>
      <li class="landing-menu-item">
        <a href="#pricing">BUY NOW</a>
      </li>
    </ul>
    <a onclick="openMenu()" id="landing-menu-button" tabindex="0">
      <i class="pi pi-bars"></i>
    </a>
    <ul class="contact-menu">
      <li>
        <a href="#contact">CONTACT</a>
      </li>
    </ul>
  </div>
  <div class="landing-header-content">
    <div class="header-text">
      <h1>FORESTFALCON<sup>TM</sup></h1>
      <p>All in one forestry solution that represents what forestry should be like in the 21<sup>th</sup> century.
      </p>
      <button class="landing-btn"><span>LEARN MORE</span></button>
    </div>
    <div class="header-image">
      <img class="lazy" src="../../assets/header-image.png" alt="ForestFalcon"/>
    </div>
  </div>
</div>
