<div id="meetDiamond" class="landing-meetdiamond">
  <div class="section-title">
    <h1>Meet FORESTFALCON</h1>
    <span>Modern forestry solution.</span>
  </div>
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-3">
      <div class="card widget-feature-box">
        <i class="fa fa-tablet" style="color: #007CBE; font-size: 40px"></i>
        <h2>Responsive</h2>
        <span>Works on a plethora of devices.</span>
      </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <div class="card widget-feature-box">
        <i class="fa fa-play-circle" style="color: #007CBE; font-size: 40px"></i>
        <h2>Simplicity</h2>
        <span>Simple and powerful.</span>
      </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <div class="card widget-feature-box">
        <i class="fa fa-calculator" style="color: #007CBE; font-size: 40px"></i>
        <h2>Overview</h2>
        <span>All documents and reports in one place.</span>
      </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <div class="card widget-feature-box">
        <i class="fa fa-globe" style="color: #007CBE; font-size: 40px"></i>
        <h2>Cloud based</h2>
        <span>Access FORESTFALCON<sup>TM</sup> from anywhere. Anytime.</span>
      </div>
    </div>
  </div>
</div>

<div id="features" class="landing-features">
  <div class="feature-row">
    <div class="feature-text">
      <h2>Wood management</h2>
      <span>
                    One log, one story. See that story. Asset tracking and management provided by FORESTFALCON<sup>TM</sup>
                    will bring that story to you with a few clicks. You will have a complete history and logistical transparency at your disposal.
                    GPS coordinates, timestamps, pictures and videos are just a part of it.
                </span>
    </div>
    <div class="feature-image">
      <img class="lazy" alt="feature"
           src="../../assets/stock.png"/>
    </div>
  </div>
  <div class="feature-row row-reverse">
    <div class="feature-text">
      <h2>Real time data transfer</h2>
      <span>
                    With our devices and mobile data connectivity all field documentation and media is transferred to the FORESTFALCON<sup>TM</sup>
                    cloud. That information is immediately at your disposal the web interface or other devices on the field.</span>
    </div>
    <div class="feature-image">
      <img class="lazy" alt="feature"
           src="../../assets/feature-image-2.png"/>
    </div>
  </div>
  <div class="feature-row">
    <div class="feature-text">
      <h2>Offline mode</h2>
      <span>
                    No connectivity? No problem. Our on-device software is designed to work without a constant internet connection as well.
                    Heading out in the field to sell? Just sync the stock data before leaving and the data is stored on the device.
                    When a data connection is available just sync the data again and everything is stored on the cloud.
                    You can even omit the first part if you're using the buying mode.
                </span>
    </div>
    <div class="feature-image">
      <img alt="feature"
           src="../../assets/feature-image-3.png"/>
    </div>
  </div>
</div>

<div id="pricing" class="landing-pricing">
  <div class="section-title">
    <h1>Our Pricing</h1>
    <span>Simple monthly pricing model</span>
  </div>
  <div class="pricing-cards">
    <div class="widget-pricing-card">
      <h2>BASIC</h2>
      <span>Starting from</span>
      <h3>€350</h3>
      <ul>
        <li>Selling mode</li>
        <li>Buying mode</li>
        <li>5 users</li>
        <li>Unlimited Storage</li>
      </ul>
      <a href="#">ORDER NOW</a>
    </div>
    <div class="widget-pricing-card deal">
      <h2>PRO</h2>
      <span>Starting from</span>
      <h3>€450</h3>
      <ul>
        <li>Selling mode</li>
        <li>Buying mode</li>
        <li>Check mode</li>
        <li>Up to 10 users</li>
        <li>Reports</li>
        <li>Mobile printing</li>
        <li>Unlimited Storage</li>
      </ul>
      <a href="#">ORDER NOW</a>
    </div>
    <div class="widget-pricing-card">
      <h2>ELITE</h2>
      <span>Starting from</span>
      <h3>€590</h3>
      <ul>
        <li>Selling mode</li>
        <li>Buying mode</li>
        <li>Check mode</li>
        <li>Overselling</li>
        <li>Pricing</li>
        <li>Up to 50 users</li>
        <li>Reports</li>
        <li>Mobile printing</li>
        <li>Unlimited Storage</li>
      </ul>
      <a href="#">ORDER NOW</a>
    </div>
  </div>
</div>
